<template>
  <div>
    <nav
      class="
        navbar navbar-expand-md
        bg-nav-top
        d-flex
        justify-content-between
        py-2
        pr-2
      "
    >
      <router-link class="d-flex ml-5" to="/welcome">
        <div class="border-right pr-2" style="border-color: yellow">
          <img src="~@/assets/images/logo-main.png" height="40" />
        </div>
        <span class="my-auto h4 ml-2 text-white" style="letter-spacing: 5px"
          >MEMBER</span
        >
      </router-link>

      <div class="d-flex">
        <span class="h5 my-auto" style="width: 340px">{{ currentTime }}</span>
        <button
          class="btn btn-primary rounded-0"
          style="height: 43px"
          @click="reload"
          :disabled="disabledReload"
        >
          <i class="fas fa-redo"></i>
        </button>
      </div>
    </nav>
    <div class="d-flex">
      <nav role="navigation" class="sidebar-bg-dark" id="navigation">
        <div class="scrollbar scrollbar-use-navbar scrollbar-bg-dark pt-1">
          <div id="account-summary" class="mt-1">
            <ul style="border: 0" class="text-nowrap">
              <li>
                <span class="prop">ชื่อ:</span
                ><span>{{ currentUser.username }}</span>
              </li>
              <li>
                <span class="prop">ระดับ:</span
                ><span>{{ currentUser.levelTH }}</span>
              </li>
              <li>
                <span class="prop">จำนวนเงิน:</span>
                <span
                  :class="{
                    positive: currentUser.creditBalance >= 0,
                    negative: currentUser.creditBalance < 0,
                  }"
                  >{{ util.formatMoney(currentUser.creditBalance) }}</span
                >
              </li>
            </ul>
          </div>
          <ul class="list list-unstyled list-bg-dark mb-0" id="list-sidebar">
            <li class="list-item" v-for="item in menus" :key="item.id">
              <router-link
                :to="item.path"
                class="list-link"
                exact-active-class="link-current up active down"
              >
                <span class="list-icon"><i :class="item.icon"></i></span>
                {{ item.name }}
              </router-link>
            </li>
          </ul>
          <div class="mt-4 px-1" v-if="categories.length">
            <h6 class="d-flex justify-content-between">
              <strong>อัตราจ่าย</strong>
              <span v-if="lottoPay">{{ lottoPay.name }}</span>
            </h6>
            <table
              class="
                table table-sm
                w-100
                table-bordered table-striped
                bg-light
                text-small
              "
            >
              <thead class="text-black">
                <tr>
                  <th class="align-middle text-center">ประเภท</th>
                  <th class="align-middle text-center">จ่าย (บาท)</th>
                  <th class="align-middle text-center">ลด (%)</th>
                  <th class="align-middle text-center">ขั้นต่ำ</th>
                </tr>
              </thead>
              <tbody class="text-nowrap">
                <tr v-for="cate in categories" :key="cate.huayCategoryId">
                  <td class="text-center">{{ cate.huayCategoryName }}</td>
                  <td class="text-right">{{ cate.payrate }}</td>
                  <td class="text-right">{{ cate.commission }}</td>
                  <td class="text-left">
                    {{ cate.userHuayCategoryBuyMin }} -
                    {{ cate.userHuayCategoryBuyMaxPerBill }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </nav>
      <div class="container-wrapper">
        <div class="border-bottom py-1 px-2" v-if="$route.name != 'Login'">
          <a @click="$router.go(-1)" class="btn text-light p-0"
            ><i class="fas fa-chevron-left"></i> กลับ</a
          >
        </div>
        <div class="container-fluid py-3">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>


<style style="scss" scoped>
.dropdown-header {
  background-color: #28681b;
  color: white;
}

.bg-nav-top {
  background: linear-gradient(
    93deg,
    rgba(194, 56, 25, 1) 9%,
    rgba(241, 90, 34, 1) 34%,
    rgba(255, 161, 51, 1) 70%,
    rgba(241, 90, 34, 1) 91%
  );
}

a.navbar-brand {
  color: white;
  font-size: 1.4rem;
}

.navbar-toggler-icon {
  color: white;
}

.navbar {
  padding: 0;
}

.sidebar-bg-dark {
  background-color: rgb(42, 45, 53);
}

#list-sidebar li {
  font-size: 1rem;
}

#account-summary {
  color: white;
}

#sidebar.menu-min #account-summary {
  display: none;
}

#account-summary ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #e5e5e5;
  text-align: left !important;
}

#account-summary ul li {
  font-size: 1rem;
}

#account-summary ul li span.prop {
  display: inline-block;
  width: 90px;
  text-align: right;
  margin-right: 4px;
}

#account-summary ul li span {
  font-weight: bold;
}
</style>


<script>
export default {
  data() {
    return {
      menus: [
        {
          name: "แทงหวย",
          icon: "fas fa-dice",
          path: "/bet",
        },
        {
          name: "รายการแทง",
          icon: "fas fa-receipt",
          path: "/bill",
        },
        {
          name: "บัญชีการเงิน",
          icon: "fas fa-money-check-alt",
          path: "/report/by-date",
        },
        {
          name: "ผลรางวัล",
          icon: "fas fa-award",
          path: "/report/game-result",
        },
        {
          name: "ลิงก์ดูผล",
          icon: "fas fa-link",
          path: "/report/game-result/about",
        },
        {
          name: "ข้อมูลผู้ใช้",
          icon: "fas fa-user",
          path: "/profile",
        },
        {
          name: "ออกจากระบบ",
          icon: "fas fa-sign-out-alt",
          path: "/logout",
        },
      ],
      currentTime: "",
    }
  },
  computed: {
    disabledReload() {
      return !this.$store.state.reloadTimeout
    },
    currentUser() {
      return this.$store.state.auth.user.userData
    },
    lottoPayId() {
      return this.$store.state.lotto.currentLottoPayId
    },
    lottoPay() {
      return this.$store.state.lotto.lottoPays.find(
        (ele) => ele.id == this.lottoPayId
      )
    },
    categories() {
      return this.$store.getters["lotto/currentLottoCategoryPays"]
    },
  },
  methods: {
    reload() {
      this.$store.dispatch("forceReloadViewDelay")
    },
    makeCurrentTime() {
      let date = this.$date()
      this.currentTime = `วันที่ ${date.format("DD")} ${date.format("MMMM")} ${
        parseInt(date.format("YYYY")) + 543
      } ${date.format("HH:mm:ss")}`
    },
  },
  beforeCreate: function () {
    document.body.className = ""
  },
  async created() {
    this.makeCurrentTime()

    setInterval(() => {
      this.makeCurrentTime()
    }, 1000)

    this.$store.dispatch("auth/updateUserData")

    // try {
    // let res = await this.axios({
    //   method: "get",
    //   url: "huay/category-pay",
    // });
    // this.$store.commit("lotto/setCurrentLottoCategoryPays", res.data.data);
    // } catch (e) {
    //   console.log(e);
    // }
  },
}
</script>